import NewRecipeItem from "./NewRecipeItem";
import "./index.css";

export default function Create() {
  return (
    <div id="create-page">
      <NewRecipeItem />
    </div>
  );
}
